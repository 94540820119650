@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Tabs {
    z-index: 1;

    &-Tabs {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: auto;
        overflow-x: auto;
        margin-bottom: 25px;
    }

    &-Tab {
        color: $color-neutral-50;
        font-size: 16px;
        line-height: 24px;
        margin: 0 30px;
        padding: 8px 0;
        cursor: pointer;

        @include default-transition(color);

        &::after {
            width: 0;
            height: 3px;
            background: $color-primary-50;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;

            @include default-transition(width, .2s);
        }

        &_isActive {
            color: $color-neutral-90;
            font-size: 20px;
            line-height: 27px;

            &::after {
                width: 32px;
            }
        }

        h1, h2, h3, h4, h5, h6 {
            font-weight: inherit;
            font-size: inherit;
            color: inherit;
            margin: 0;
        }
    }

    &-Content {
        opacity: 0;
        visibility: hidden;
        height: 0;
        overflow: hidden;

        &_isActive {
            opacity: 1;
            visibility: visible;
            height: auto;
            overflow: visible;
        }
    }
}

@include mobileAndTablet {
    .Tabs {
        margin-right: -20px;

        &-Tabs {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: auto;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &-Tab {
            margin: 0 20px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                padding-right: 20px;
            }
        }
    }
}
