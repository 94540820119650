@mixin separatorLine {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-neutral-30;
}

@mixin backgroundConfig {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.GraphicContent.TypographyParagraph_variant_large {
    line-height: 32px;
}

.GraphicContent-Section {
    margin-bottom: 129px;

    .GraphicContent-Image_medium {
        width: 100%;
        height: 324px;
        overflow: hidden;

        img {
            width: auto;
            min-height: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &::after {
        @include separatorLine();

        bottom: -64px;
    }

    &:last-of-type {
        margin-bottom: 40px;

        &::after {
            display: none;
        }
    }

    &_variant {
        &_single_column {
            .GraphicContent.TypographyHeader_variant_semi_large {
                margin-bottom: 32px;

                &:nth-of-type(2) {
                    margin-top: 48px;
                    margin-bottom: 24px;
                }
            }
        }

        &_dual_column {
            .GraphicContent.TypographyHeader_variant_semi_large {
                margin-bottom: 4px;
            }

            .GraphicContent-Card.GraphicContent-Card_variant_text {
                width: calc(50% - 12px);
                max-width: 100%;
                flex-grow: 1;
            }
        }

        &_triple_column {
            .GraphicContent-Card.GraphicContent-Card_variant_text {
                width: calc(50% - 12px);
                max-width: 100%;
                flex-grow: 1;
            }

            .GraphicContent.TypographyHeader_variant_semi_large {
                margin-bottom: 48px;
            }

            .GraphicContent-Card {
                &_variant_image {
                    .GraphicContent-Button {
                        width: 192px;
                    }
                }

                &_variant_text {
                    max-width: 100%;
                    flex-grow: 1;
                }
            }
        }

        &_faq {
            .TypographyHeader.TypographyHeader_variant_semi_large {
                margin-bottom: 38px;
            }
        }
    }
}

.GraphicContent-CardsBox {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 24px;

    &:nth-of-type(2),
    &:last-of-type {
        margin-bottom: 32px;
    }
}

.GraphicContent-Card {
    display: flex;
    flex-direction: column;
    width: calc(50% - 12px);
    margin: 32px 0 0;

    &_variant {
        &_advertise {
            width: 100%;
            max-width: 100%;
            height: 400px;
            padding: 117px;
            margin: 0;
            overflow: hidden;

            .GraphicContent-Heading {
                color: $color-neutral-5;
                margin-top: 0;
                margin-bottom: 19px;
            }

            .GraphicContent-Button {
                font-size: 20px;
                line-height: 28px;
                width: 290px;
                height: 72px;
                margin-top: 19px;
                margin-left: 11px;
                font-weight: 500;
            }

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: auto;
                height: auto;
                min-width: 100%;
                min-height: 100%;
            }
        }

        &_icon {
            align-items: center;
            margin-top: 48px;

            .GraphicContent.TypographyHeader_variant_medium {
                margin-top: 32px;
            }

            .TypographyParagraph {
                text-align: center;
                margin-bottom: 32px;
                width: 442px;
                max-width: 100%;
            }
        }

        &_image {
            align-items: center;
            background-color: $color-neutral-10;

            .GraphicContent.TypographyHeader_variant_medium {
                margin-top: 56px;
            }

            .TypographyParagraph {
                text-align: center;
                width: 442px;
                max-width: 100%;
                margin-bottom: 32px;
                padding: 0 24px;
            }

            .GraphicContent-Button {
                width: 294px;
                height: 55px;
                margin-bottom: 56px;
            }
        }

        &_text {
            .TypographyHeader.TypographyHeader_variant_medium {
                margin-top: 0;
                margin-bottom: 24px;
            }
        }
    }

    .GraphicContent.TypographyHeader_variant_medium {
        margin-bottom: 16px;
    }
}

.GraphicContent-Icon {
    cursor: pointer;
    fill: $color-primary-50;

    &:hover {
        fill: $color-primary-60;
    }
}

.CmsPage-Content .GraphicContent-Heading,
.GraphicContent-Text {
    text-align: left;
}

.GraphicContent-Heading {
    color: $color-neutral-90;

    &_underlined {
        margin-bottom: 81px;
        position: relative;

        &::after {
            @include separatorLine();

            bottom: -16px;
        }
    }
}

.GraphicContent .TypographyHeader {
    &_variant {
        &_semi_large {
            font-size: 30px;
            line-height: 42px;
            margin-bottom: 32px;
        }

        &_medium {
            margin-bottom: 32px;
        }

        &_normal {
            margin-bottom: 24px;
        }
    }
}

.GraphicContent.Accordion-Box {
    border-color: $color-neutral-30;
    padding: 24px 0;

    .Accordion-Header {
        font-size: 16px;
        font-weight: 500;
        transition: .1s;

        &::after {
            content: '';
            position: absolute;
            top: 4px;
            right: 0;
            transform: rotate(135deg);
            line-height: 20px;
            border: solid $color-neutral-90;
            width: 8px;
            height: 8px;
            border-width: 2px 2px 0 0;
            transition: .25s;
        }
    }

    &_isOpen {
        .Accordion-Header {
            color: $color-primary-50;
            padding-bottom: 32px;
            transition: .25s;

            &::after {
                transform: rotate(-45deg);
                border-color: $color-primary-50;
            }
        }
    }

    .Accordion-Text {
        font-size: 16px;
        line-height: 32px;
        color: $color-neutral-70;
        transition: max-height .1s;
    }

    &:first-of-type {
        border-top: none;
    }
}

@include desktop {
    .GraphicContent-Section_variant_triple_column {
        .GraphicContent-Card,
        .GraphicContent-Card.GraphicContent-Card_variant_text {
            max-width: calc(33% - 24px);
            width: calc(33% - 24px);
        }
    }
}

@include tablet {
    .GraphicContent-Heading_underlined {
        margin-bottom: 65px;

        &::after {
            bottom: -32px;
        }
    }

    .GraphicContent-Section {
        margin-bottom: 65px;

        &:last-of-type {
            margin-bottom: 64px;
        }

        &_variant {
            &_triple_column {
                .GraphicContent.TypographyHeader_variant_semi_large {
                    margin-bottom: 0;
                }

                .GraphicContent-Card {
                    width: calc(50% - 12px);
                    max-width: calc(50% - 12px);
                }

                .GraphicContent-Card.GraphicContent-Card_variant_text {
                    width: calc(50% - 12px);
                    max-width: 100%;
                    flex-grow: 1;
                }

                .GraphicContent-Card_variant_image .GraphicContent-Button {
                    width: 294px;
                }
            }
        }

        &::after {
            @include separatorLine();

            bottom: -32px;
        }

        .GraphicContent-CardsBox {
            &_dual:nth-of-type(2),
            &_triple:nth-of-type(2) {
                margin-bottom: 0;
            }

            &_dual {
                flex-direction: row;
            }
        }

        .GraphicContent-Card {
            &:nth-of-type(3) {
                margin: 32px auto 0;
            }

            &_variant {
                &_advertise {
                    height: 300px;
                    padding: 83px 48px;

                    .GraphicContent-Heading {
                        font-size: 30px;
                        line-height: 42px;
                        margin-bottom: 16px;
                    }

                    .GraphicContent-Button {
                        width: 248px;
                        margin-top: 16px;
                        margin-left: 0;
                    }
                }

                &_icon {
                    margin-top: 32px;

                    .GraphicContent.TypographyParagraph_variant_large {
                        font-size: 14px;
                        line-height: 28px;
                    }
                }

                &_image {
                    .GraphicContent.TypographyHeader_variant_medium {
                        margin-top: 32px;
                    }

                    .GraphicContent.TypographyParagraph_variant_large {
                        font-size: 14px;
                        line-height: 28px;
                    }

                    .GraphicContent-Button {
                        width: 264px;
                    }
                }

                &_text {
                    .TypographyHeader.TypographyHeader_variant_medium {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    .GraphicContent .TypographyHeader {
        &_variant {
            &_semi_large {
                margin-bottom: 24px;
            }

            &_medium {
                margin-bottom: 24px;
            }
        }
    }

    .GraphicContent-Section_variant_single_column {
        .GraphicContent.TypographyHeader_variant_semi_large:nth-of-type(2) {
            margin-top: 36px;
            margin-bottom: 16px;
        }
    }

    .GraphicContent.TypographyHeader_variant_semi_large {
        margin-bottom: 0;
    }

    .GraphicContent.Accordion-Box {
        .Accordion-Header {
            font-size: 14px;
            line-height: 21px;

            &::after {
                top: 3px;
            }
        }

        .Accordion-Text {
            font-size: 14px;
            line-height: 28px;
        }
    }

    .GraphicContent.GraphicContent-Image_medium {
        height: 248px;
    }
}

@include mobile {
    .GraphicContent-Section {
        margin-bottom: 65px;

        &:last-of-type {
            margin-bottom: 56px;
        }

        &::after {
            bottom: -32px;
        }

        &_variant {
            &_single_column {
                .GraphicContent.TypographyHeader_variant_semi_large {
                    font-size: 20px;
                    line-height: 27px;

                    &:nth-of-type(2) {
                        margin-top: 32px;
                        margin-bottom: 16px;
                    }
                }

                .GraphicContent.TypographyParagraph_variant_large {
                    font-size: 14px;
                    line-height: 28px;
                }
            }

            &_dual_column,
            &_triple_column {
                .GraphicContent.TypographyHeader_variant_semi_large {
                    font-size: 20px;
                    line-height: 27px;
                    margin-bottom: 0;
                }

                .GraphicContent.TypographyParagraph_variant_large {
                    font-size: 14px;
                    line-height: 28px;
                }

                .GraphicContent-Card.GraphicContent-Card_variant_text {
                    width: 100%;
                }

                .GraphicContent-Card_variant_image .GraphicContent-Button {
                    width: 264px;
                }
            }

            &_faq {
                .GraphicContent.TypographyHeader_variant_semi_large {
                    font-size: 20px;
                    line-height: 27px;
                    margin-bottom: 16px;
                }
            }
        }
    }

    .GraphicContent-CardsBox {
        gap: 0;
    }

    .GraphicContent-Card {
        width: 100%;

        &_variant {
            &_advertise {
                height: 180px;
                padding: 43px 0 38px 24px;

                .GraphicContent-Heading {
                    font-size: 16px;
                    line-height: 22.4px;
                    margin-bottom: 7px;

                    span {
                        display: block;
                    }
                }

                .GraphicContent-Button {
                    margin-top: 8px;
                    font-size: 12px;
                    line-height: 28px;
                    width: 131px;
                    height: 40px;
                    margin-left: 0;
                }
            }

            &_icon {
                margin-top: 48px;

                &:first-of-type {
                    margin-top: 32px;
                }
            }

            &_image {
                .GraphicContent.TypographyHeader_variant_medium {
                    margin-top: 32px;
                }
            }

            &_text {
                .TypographyHeader {
                    text-align: left;
                }
            }
        }
    }

    .GraphicContent-Heading_underlined {
        font-size: 24px;
        line-height: 32.4px;
        margin-bottom: 49px;

        &::after {
            bottom: -16px;
        }
    }

    .GraphicContent .TypographyHeader {
        &_variant {
            &_semi_large {
                font-size: 24px;
                line-height: 32.4px;
                margin-bottom: 16px;
            }

            &_medium {
                font-size: 20px;
                line-height: 27px;
                margin-bottom: 16px;
            }

            &_normal {
                font-size: 15px;
                line-height: 21px;
                margin-bottom: 16px;
            }
        }
    }

    .GraphicContent.Accordion-Box {
        padding: 16px 0;

        .Accordion-Header {
            font-size: 14px;
            line-height: 28px;

            &::after {
                top: 3px;
            }
        }

        .Accordion-Text {
            font-size: 14px;
            line-height: 28px;
        }
    }
}
