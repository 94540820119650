@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.lazy-load-image-background {
    display: block;
    width: 100%;
    height: 100%;
}

.ProductCard {
    &-Figure, &-FigureAlternative {
        .lazy-load-image-background {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
