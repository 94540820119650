@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DeliveryNotification {
    position: absolute;
    background: $color-neutral-5;
    padding: 15px 10px;
    border: 1px solid $color-neutral-40;
    border-radius: 4px;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    z-index: 1;
    color: $color-neutral-70;

    &-Close {
        background: $color-primary-50;
        color: $color-neutral-5;
        padding: 5px 0;
        width: 50px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        cursor: pointer;
        transition: background .25s ease-out;

        &:hover {
            background: $color-primary-70;
        }
    }
}
