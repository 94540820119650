@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PasswordField {
    position: relative;

    &_disabled {
        .PasswordField-Switcher {
            opacity: 0.3;
            cursor: initial;
        }
    }

    &-Switcher {
        position: absolute;
        top: 52px;
        right: 13px;
        opacity: 0.6;
        cursor: pointer;

        &_noLabel {
            top: 12px;
        }
    }

    .Field {
        &_hasError {
            + .PasswordField-Switcher {
                opacity: 1;

                svg {
                    path {
                        fill: $color-secondary-50;
                    }
                }
            }
        }
    }
}
