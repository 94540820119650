@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ContactForm {
    textarea {
        width: 100%;
        min-height: 200px;
    }

    .Field-Wrapper:first-of-type .Field {
        margin-top: 0;
    }
}
