@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NotificationList {
    inset-block-start: 30px;
    max-width: 462px;
    width: 100%;
}

@include mobile {
    .NotificationList {
        max-width: 100%;
        inset-block-start: 0;
    }
}
