@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PromoBanner {
    &-Banner {
        margin: 10px 0;

        &_isClickable {
            cursor: pointer;
        }
    }
}
