@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --reset-button-label-color: #{$color-neutral-5};
    --reset-button-background: #{$color-primary-50};
    --reset-button-icon-color: #{$color-neutral-5};
}

.ResetButton {
    display: flex;
    align-items: center;
    background: var(--reset-button-background);
    padding: 8px 36px 8px 12px;
    position: relative;
    cursor: pointer;

    &-Label {
        font-size: 12px;
        color: var(--reset-button-label-color);
    }

    &:hover {
        .ResetButton-CloseIcon {
            transform: rotate(-90deg);
        }
    }

    &-CloseIcon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 36px;
        transform: rotate(0);

        @include default-transition(transform, .2s);

        .CloseIcon {
            width: 24px;
            height: 24px;
            fill: var(--reset-button-icon-color);
        }
    }
}
