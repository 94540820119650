@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CarouselScrollArrow {
    z-index: 1;
    transition: opacity 200ms;
    cursor: pointer;
    transform: rotate(-180deg);
    width: 18px;
    height: 18px;

    .ChevronIcon {
        width: 100%;
        height: 100%;
    }

    &_isNextArrow {
        transform: none;
    }

    &_isInvisible {
        visibility: hidden;
    }
}
