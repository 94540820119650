@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SliderPrevArrow {
    left: 10px;
    cursor: pointer;
    position: absolute;
    bottom: -14px;

    &_disabled {
        svg {
            opacity: 0.4;
        }
    }

    svg {
        @include default-transition(right, .2s);

        right: 0;
    }

    &:hover {
        svg {
            right: 4px;
        }
    }
}

@include desktopAndAbove {
    .SliderPrevArrow {
        position: absolute;
        bottom: 50%;
        z-index: 1;
        transform: translateY(50%);
    }
}

@include above1480 {
    .SliderPrevArrow {
        left: -44px;
    }
}
