:root {
    --table-header-color: #{$color-neutral-10};
    --table-body-border: 1px solid #{$color-neutral-20};
}

table {
    border: var(--table-body-border);

    thead th {
        background-color: var(--table-header-color);
    }

    tbody tr:not(:last-child) {
        border-block-end: var(--table-body-border);
    }

    td,
    th {
        padding: 17px 30px 16px;
        text-align: start;
        min-width: 120px;

        + th,
        + td {
            padding-left: 0;
        }
    }

    th {
        font-weight: 500;
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: auto;
}

@include mobile {
    table {
        td,
        th {
            padding: 15px 24px;
            min-width: 110px;
        }
    }
}
