@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.BlogListing {
    .ContentWrapper {
        max-width: 1440px;
        margin: auto;
    }

    .CategoryPagination {
        justify-content: flex-start;
        margin-top: 20px;
    }

    @include desktop {
        margin-bottom: 60px;
    }

    &-GridWrapper {
        width: 100%;

        @include tabletAndAbove {
            margin-right: 3rem;
            width: 75%;
        }

    }

    &-ColumnWrapper {
        display: flex;

        @include mobile {
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    &-Grid {
        display: grid;
        grid-gap: 11px 10px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(1, 1fr);
        flex-wrap: wrap;

        .BlogPostCard {
            width: 100%;
            margin: auto;

            &-ContentWrapper {
                height: 100%;

                .Image {
                    &-Image {
                        width: 100%;
                        height: 100%;
                        object-fit: fill;
                    }
                }
            }
        }

        @include mobile {
            order: 1;
        }

        @include desktop {
            grid-gap: 16px;
            padding: 0;
            grid-template-columns: repeat(1, 1fr);
            flex-basis: 70%;
            max-width: 1084px;
            margin: auto;
        }
    }

    &-Title {
        text-align: left;
        font-style: normal;
        font-size: 3.2rem;
        line-height: 4rem;
        margin-top: 0;
        margin-bottom: 24px;
        font-weight: 500;
    }

    &-Search {
        @include mobile {
            display: none;
        }
    }

    &-LoadMoreWrapper {
        padding-top: 40px;
        text-align: center;

        @include mobile {
            order: 2;
            width: 100%;
        }

        flex-basis: 100%;
    }

    & &-LoadMoreButton {
        width: 158px;
        height: 56px;
        margin: auto;
        color: var(--load-more-button-color);
        background-color: var(--load-more-button-background);
        border: none;
        border-radius: 3px;
        @include button;
    }
}


.Blog-Sidebar {
    width: 100%;

    @include tabletAndAbove {
        width: 25%;
    }
}


.Blog-Sidebar {
    h4.TypographyHeader {
        font-size: 2.2rem;
        line-height: 3.2rem;
    }
}

@include mobile {
    .BlogListing {
        .CategoryPagination {
            justify-content: center;
        }
    }
}
