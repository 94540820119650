@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LowestPriceIn30Days {
    & p {
        font-size: 12px;
        line-height: 18px;
        color: $color-neutral-80;
    }
}
