.TypographyHeader {
    color: $color-neutral-90;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 0;

    &_variant {
        &_extra_large {
            font-size: 56px;
            line-height: 78px;
            margin-bottom: 32px;
        }

        &_large {
            font-size: 42px;
            line-height: 54px;
            margin-bottom: 24px;
        }

        &_medium {
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 24px;
        }

        &_normal {
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 27px;
        }

        &_small {
            font-size: 15px;
            line-height: 21px;
            margin-bottom: 20px;
        }

        &_extra_small {
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 16px;
        }
    }

    &_fontWeight {
        &_thin {
            font-weight: 100;
        }

        &_extra_light {
            font-weight: 200;
        }

        &_light {
            font-weight: 300;
        }

        &_regular {
            font-weight: 400;
        }

        &_medium {
            font-weight: 500;
        }

        &_semi_bold {
            font-weight: 600;
        }

        &_bold {
            font-weight: 700;
        }

        &_extra_bold {
            font-weight: 800;
        }

        &_black {
            font-weight: 900;
        }
    }
}

@include tabletAndAbove {
    .TypographyHeader {
        &_variant {
            &_medium {
                font-size: 30px;
                line-height: 42px;
            }
        }
    }
}
