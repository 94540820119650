@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/poppins-regular.eot');
    src: url('../../assets/fonts/Poppins/poppins-regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Poppins/poppins-regular.woff2') format('woff2'),
        url('../../assets/fonts/Poppins/poppins-regular.woff') format('woff'),
        url('../../assets/fonts/Poppins/poppins-regular.ttf') format('truetype'),
        url('../../assets/fonts/Poppins/poppins-regular.svg') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/poppins-italic.eot');
    src: url('../../assets/fonts/Poppins/poppins-italic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Poppins/poppins-italic.woff2') format('woff2'),
        url('../../assets/fonts/Poppins/poppins-italic.woff') format('woff'),
        url('../../assets/fonts/Poppins/poppins-italic.ttf') format('truetype'),
        url('../../assets/fonts/Poppins/poppins-italic.svg') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/poppins-500.eot');
    src: url('../../assets/fonts/Poppins/poppins-500.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Poppins/poppins-500.woff2') format('woff2'),
        url('../../assets/fonts/Poppins/poppins-500.woff') format('woff'),
        url('../../assets/fonts/Poppins/poppins-500.ttf') format('truetype'),
        url('../../assets/fonts/Poppins/poppins-500.svg') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/poppins-500italic.eot');
    src: url('../../assets/fonts/Poppins/poppins-500italic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Poppins/poppins-500italic.woff2') format('woff2'),
        url('../../assets/fonts/Poppins/poppins-500italic.woff') format('woff'),
        url('../../assets/fonts/Poppins/poppins-500italic.ttf') format('truetype'),
        url('../../assets/fonts/Poppins/poppins-500italic.svg') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/poppins-600.eot');
    src: url('../../assets/fonts/Poppins/poppins-600.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Poppins/poppins-600.woff2') format('woff2'),
        url('../../assets/fonts/Poppins/poppins-600.woff') format('woff'),
        url('../../assets/fonts/Poppins/poppins-600.ttf') format('truetype'),
        url('../../assets/fonts/Poppins/poppins-600.svg') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/poppins-600italic.eot');
    src: url('../../assets/fonts/Poppins/poppins-600italic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Poppins/poppins-600italic.woff2') format('woff2'),
        url('../../assets/fonts/Poppins/poppins-600italic.woff') format('woff'),
        url('../../assets/fonts/Poppins/poppins-600italic.ttf') format('truetype'),
        url('../../assets/fonts/Poppins/poppins-600italic.svg') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/poppins-700.eot');
    src: url('../../assets/fonts/Poppins/poppins-700.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Poppins/poppins-700.woff2') format('woff2'),
        url('../../assets/fonts/Poppins/poppins-700.woff') format('woff'),
        url('../../assets/fonts/Poppins/poppins-700.ttf') format('truetype'),
        url('../../assets/fonts/Poppins/poppins-700.svg') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/poppins-700italic.eot');
    src: url('../../assets/fonts/Poppins/poppins-700italic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Poppins/poppins-700italic.woff2') format('woff2'),
        url('../../assets/fonts/Poppins/poppins-700italic.woff') format('woff'),
        url('../../assets/fonts/Poppins/poppins-700italic.ttf') format('truetype'),
        url('../../assets/fonts/Poppins/poppins-700italic.svg') format('svg');
    font-weight: 700;
    font-style: italic;
}
