@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PasswordChangePage {
    &-Wrapper {
        margin-bottom: 100px;
    }

    .Button {
        width: 100%;
        margin-top: 28px;
    }
}

@include mobileAndTablet {
    .PasswordChangePage {
        &-Wrapper {
            max-width: 100%;
        }
    }
}

@include desktopAndAbove {
    .PasswordChangePage {
        text-align: center;

        &-Wrapper {
            max-width: 560px;
        }
    }
}
