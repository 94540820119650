@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ContactPage {
    margin-bottom: 60px;

    .ContentWrapper {
        @include page-container;
    }

    .TypographyHeader {
        margin-top: 0;
    }

    &-ColumnWrapper {
        display: flex;
        margin-block-end: 24px;

        @include mobile {
            display: block;
            padding: 12px 0;
        }
    }

    &-Column {
        flex: 1;

        &_isContent {
            margin-inline-end: 30px;

            @include mobile {
                margin-inline-end: 0;
            }
        }
    }

    .Form {
        .Button {
            @include mobile {
                width: 100%;
            }
        }
    }

    .ContactInfo {
        &-ListTitle {
            font-weight: 600;
        }

        &-Box {
            display: flex;
            margin-bottom: 30px;
        }

        &-List {
            padding-left: 16px;
            width: 100%;
        }

        &-ListItem {
            display: flex;
            line-height: 20px;

            &::before {
                display: none;
            }

            span {
                flex: 0 0 50%;
            }

            a {
                color: $color-primary-70;
            }
        }
    }
}

@include mobile {
    .ContactPage {
        .ContactInfo {
            &-ListItem {
                flex-direction: column;
            }
        }
    }
}
