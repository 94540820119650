.StaticContent-Section {
    margin-bottom: 64px;

    &:last-of-type {
        margin-bottom: 100px;
    }
}

.CmsPage-Content .StaticContent-Heading,
.StaticContent-Text {
    text-align: left;
}

.StaticContent-Heading {
    color: $color-neutral-90;

    &_underlined {
        position: relative;
        margin-bottom: 81px;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -16px;
            width: 100%;
            height: 1px;
            background-color: $color-neutral-30;
        }
    }
}

.StaticContent-SectionWrapper .TypographyHeader {
    &_variant {
        &_semi_large {
            font-size: 30px;
            line-height: 42px;
            margin-bottom: 32px;
        }

        &_medium {
            margin-bottom: 32px;
        }

        &_normal {
            margin-bottom: 24px;
        }
    }
}

.StaticContent-SectionWrapper {
    &_variant_list,
    &_variant_table {
        .TypographyHeader_variant_semi_large {
            margin-bottom: 24px;
        }
    }
}

.StaticContent-ListsBox {
    display: flex;
    flex-wrap: wrap;

    &_ul,
    &_ol {
        margin: 0;

        p {
            line-height: 40px;
        }
    }

    &_ul {
        width: calc(50% - 12px);
        margin-right: 20px;

        li {
            &::before {
                content: '';
                background-color: $color-neutral-70;
                border-radius: 20px;
                width: 4px;
                height: 4px;
                top: 19px;
                left: 4px;
            }
        }
    }

    &_ol {
        width: calc(50% - 12px);
        margin-left: 4px;

        li {
            &::before {
                top: 11px;
                color: $color-neutral-70;
            }
        }
    }
}

.StaticContent-Table {
    width: 100%;

    &_row {
        display: flex;

        p {
            width: 50%;
            padding: 24px 32px;
            border-bottom: solid 1px $color-neutral-20;

            &:first-of-type {
                border-left: solid 1px $color-neutral-20;
                color: $color-neutral-90;
            }

            &:last-of-type {
                border-right: solid 1px $color-neutral-20;
                padding: 24px 32px 24px 0;
            }
        }
    }

    &_headers {
        background-color: $color-neutral-10;

        p {
            padding: 16px 32px;
            border: none;
            color: $color-neutral-90;
            font-weight: 500;

            &:first-of-type,
            &:last-of-type {
                border-left: none;
                border-right: none;
            }

            &:last-of-type {
                padding: 16px 32px 16px 0;
            }
        }
    }
}

@include tablet {
    .StaticContent-Section:last-of-type {
        margin-bottom: 64px;
    }

    .StaticContent-Heading_underlined {
        margin-bottom: 65px;

        &::after {
            bottom: -32px;
        }
    }

    .StaticContent-SectionWrapper .TypographyHeader {
        &_variant {
            &_semi_large {
                margin-bottom: 24px;
            }

            &_medium {
                margin-bottom: 24px;
            }
        }
    }

    .StaticContent-SectionWrapper {
        &_variant_list,
        &_variant_table {
            .TypographyHeader_variant_semi_large {
                font-size: 24px;
                line-height: 32.4px;
                margin-bottom: 32px;
            }
        }
    }

    .StaticContent-Table_row p,
    .StaticContent-Table_headers p {
        padding: 16px 24px;

        &:last-of-type {
            padding: 16px 24px 16px 0;
        }
    }
}

@include mobile {
    .StaticContent-Section {
        &:last-of-type {
            margin-bottom: 64px;
        }
    }

    .StaticContent-Heading_underlined {
        font-size: 24px;
        line-height: 32.4px;
        margin-bottom: 49px;

        &::after {
            bottom: -16px;
        }
    }

    .StaticContent-SectionWrapper .TypographyHeader {
        &_variant {
            &_semi_large {
                font-size: 24px;
                line-height: 32.4px;
                margin-bottom: 16px;
            }

            &_medium {
                font-size: 20px;
                line-height: 27px;
                margin-bottom: 16px;
            }

            &_normal {
                font-size: 15px;
                line-height: 21px;
                margin-bottom: 16px;
            }
        }
    }

    .StaticContent-SectionWrapper {
        &_variant_list,
        &_variant_table {
            .TypographyHeader_variant_semi_large {
                font-size: 24px;
                line-height: 32.4px;
                margin-bottom: 16px;
            }
        }
    }

    .StaticContent-SectionWrapper_variant_list {
        .StaticContent-Section {
            margin-bottom: 48px;
        }

        .TypographyHeader_variant_semi_large {
            margin-bottom: 0;
        }
    }

    .StaticContent-Text {
        font-size: 14px;
        line-height: 28px;
    }

    .StaticContent-ListsBox {
        flex-wrap: wrap;

        &_ul,
        &_ol {
            min-width: 300px;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        &_ul li::before {
            top: 15px;
        }

        &_ol li::before {
            top: 9px;
        }

        &_ul p,
        &_ol p {
            line-height: 35px;
        }
    }

    .StaticContent-Table {
        max-width: 100%;
        overflow-x: scroll;
        scrollbar-color: $color-neutral-20 $color-primary-50;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-primary-50;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $color-primary-60;
        }

        &::-webkit-scrollbar-track {
            background: $color-neutral-20;
        }
    }

    .StaticContent-Table_row {
        min-width: 560px;
    }

    .StaticContent-Table_row p,
    .StaticContent-Table_headers p {
        padding: 16px 24px;
        line-height: 21px;

        &:last-of-type {
            padding: 16px 24px 16px 0;
        }
    }
}
