fieldset {
    border: 0;
}

legend {
    padding-block-end: 6px;
    display: block;
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;

    @include mobile {
        font-size: 18px;
        padding-block-end: 7px;
    }
}
