@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PaginationLink {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    border: 0;
    text-decoration: none;
    font-size: 13px;
    color: $color-neutral-90;
    letter-spacing: 0.5px;
    font-weight: 400;
    transition: background, color .25s ease-out;

    &:hover, &_isCurrent {
        background: $color-neutral-20;
        color: $color-neutral-90;
    }
}
