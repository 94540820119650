@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DiscountLabel {
    color: $white;
    background: $color-secondary-50;
    padding: 7px 12px;
    font-weight: 500;
    margin-bottom: 10px;
}
