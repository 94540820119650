@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Loader {
    z-index: 1;

    &-Scale {
        top: 50%;
    }

    &_isRelative {
        position: relative;
        height: 200px;
    }
}
