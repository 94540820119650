@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.InfoBar {
    background: $color-neutral-20;
    padding: 11px 0;
    display: none;

    &-Container {
        @include page-container;

        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 18px;
        color: $color-neutral-90;
    }

    &-Middle {
        display: none;
    }

    &-Left, &-Right {
        display: flex;
        align-items: center;
    }

    &-Left {
        .InfoBar-Item {
            margin-right: 24px;
        }
    }

    &-Right {
        .InfoBar-Item {
            margin-left: 24px;
        }
    }

    &-Item {
        display: flex;
        align-items: center;
    }

    &-Bold {
        font-weight: 600;
        font-size: 14px;
    }

    &-Space {
        display: block;
        width: 4px;
        height: 4px;
        background: $color-neutral-90;
        border-radius: 50%;
        align-content: center;
        margin: 0 15px;
    }
}

@include tabletAndAbove {
    .InfoBar {
        display: block;
    }
}

@include desktopAndAbove {
    .InfoBar {
        &-Middle {
            display: flex;
        }

        &-Left {
            .InfoBar-Item {
                margin-right: 40px;
            }
        }

        &-Right {
            .InfoBar-Item {
                margin-left: 40px;
            }
        }
    }
}
