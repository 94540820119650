@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --search-item-divider-color: #eee;
    --search-item-title-color: #{$color-neutral-90};
}

.SearchItem {
    padding-inline-start: 0;
    margin: 24px 0;

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &::before {
        content: none;
    }

    &-CustomAttribute {
        color: var(--color-black);
        font-size: 12px;
        font-weight: 400;
        opacity: .5;
    }

    &-Wrapper {
        height: 60px;
        display: flex;
        align-items: center;
        grid-template-columns: 48px calc(100% - 60px);
        grid-column-gap: 12px;

        .Image_ratio_square {
            width: 79px;
            height: 60px;
            padding-bottom: 0;
            overflow: hidden;

            img {
                width: 79px;
            }
        }
    }

    &-Content {
        display: flex;
        align-items: center;

        .SearchItem-Title {
            font-size: 13px;
            line-height: 19.5px;
        }
    }
}
