@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --content-wrapper-width: #{$page-max-width-cms};
}

.CmsPage {
    margin-top: 0;

    &-Wrapper {
        padding-inline: 20px;
        margin-top: 0;
    }

    .widget {
        overflow-x: visible;
    }
}

@include tabletAndAbove {
    .CmsPage {
        &-Wrapper {
            margin-top: 0;
        }
    }
}


@include desktopAndAbove {
    .CmsPage {
        &-Wrapper {
            padding-inline: 44px;
            overflow-x: hidden; // Needed to achieve product sliders on HP with overflow content
        }
    }
}
