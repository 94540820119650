@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SideDrawer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: -100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
    visibility: hidden;

    &_visible {
        visibility: visible;
        right: 0;

        .SideDrawer-Drawer {
            opacity: 1;
            visibility: visible;
            right: 0;
        }
    }

    &-Drawer {
        background: $color-neutral-5;
        width: 100%;
        height: 100%;
        max-width: 480px;
        display: flex;
        flex-direction: column;
        position: absolute;
        opacity: 0;
        top: 0;
        right: -100%;
        padding: 64px 32px 0;

        @include default-transition(all, .2s);
    }

    &-Title {
        margin-bottom: 24px;
        line-height: 42px;
        font-size: 30px;
    }

    &-Content {
        display: block;
        flex-direction: column;
        justify-content: center;
        height: calc(100% - 100px);
        overflow-y: auto;
        padding-right: 20px;

        @include scrollbar();
    }

    &-Close {
        cursor: pointer;
        position: absolute;
        right: -8px;
        top: 5px;
        transform: rotate(0);

        @include default-transition(transform, .2s);

        &:hover {
            transform: rotate(90deg);
        }

        svg {
            display: block;
        }
    }
}

@include mobileAndTablet {
    .SideDrawer {
        padding: 20px;

        &-Close {
            top: 0;
        }

        &-Content {
            height: calc(100% - 60px);
        }

        &-Title {
            font-size: 24px;
        }
    }
}
