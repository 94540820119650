ul,
ol {
    ul,
    ol {
        counter-reset: list;
    }
}

li {
    position: relative;
    padding-left: 18px;

    &::before {
        position: absolute;
        left: 0;

        ol > & {
            content: counter(list) '.';
        }

        ul > & {
            content: '';
            width: 5px;
            height: 5px;
            background: $color-neutral-90;
            top: 8px;
        }
    }
}
