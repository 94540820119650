.Banner {
    &_type_singleRow {
        .Banner {
            &-Item {
                margin-bottom: 24px;
                overflow: hidden;
                cursor: pointer;

                &:hover {
                    img {
                        transform: scale(1.02);
                    }
                }

                a {
                    display: block;
                    height: 100%;
                }

                img {
                    @include default-transition(transform);
                }
            }

            &-Title {
                text-align: center;
            }

            &-Bar {
                position: absolute;
                left: 0;
                width: 100%;
                bottom: 0;
                text-align: center;
                background: $white-transparent;
                padding: 16px 30px;
            }

            &-Header {
                margin-bottom: 20px;
            }
        }

        p {
            margin-bottom: 0;
            height: 100%;
        }
    }
}

@include mobileAndTablet {
    .Banner {
        &_type_singleRow {
            margin-left: -20px;
            margin-right: -20px;
        }
    }
}

@include tabletAndAbove {
    .Banner {
        &_type_singleRow {
            .Banner {
                &-Bar {
                    width: 320px;
                    text-align: left;
                    padding: 30px 24px 40px;
                    min-height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                &-Item {
                    .Banner-Bar {
                        right: 0;
                        left: auto;
                    }

                    img {
                        object-fit: cover;
                    }

                    .Button {
                        align-self: flex-start;
                    }
                }
            }
        }
    }
}

@include desktopAndAbove {
    .Banner {
        &_type_singleRow {
            .Banner {
                &-Bar {
                    width: 400px;
                    padding: 30px 60px 30px;
                    justify-content: center;
                }

                &-Item {
                    height: 250px;
                }
            }
        }
    }
}
