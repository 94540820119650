@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.BrandsPage {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    .ContentWrapper {
        padding: 0 20px;
        width: 1416px;
        max-width: 100%;
    }

    section {
        display: flex;
        flex-direction: column;
    }

    &-SearchWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-neutral-20;
        width: 100vw;
        max-width: 100%;
        padding: 56px 16px 73px;
    }

    .Heading {
        &_type {
            &_large {
                font-size: 24px;
                line-height: 34px;
                font-weight: 500;
                margin-bottom: 32px;
            }

            &_medium {
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                margin-bottom: 32px;
            }
        }
    }

    &-SearchBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $color-neutral-90;
        background-color: $color-neutral-5;
        width: 100%;
        max-width: 100%;
        margin: 0 16px;

        input {
            width: 100%;
            border: none;
            background-color: $color-neutral-5;
        }
    }

    &-SearchButton {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-PopularWrapper {
        margin-top: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid $color-neutral-30;
        margin-bottom: 32px;
        max-width: 100%;
        padding-bottom: 56px;
    }

    &-PopularWrapperInner {
        max-width: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow-X: auto;
        -webkit-overflow-scrolling: touch;

        .BrandsPage-ImgWrapper {
            margin-bottom: 0;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .BrandsPage-BrandCard {
            flex: 0 0 auto;
            margin: 0;
        }
    }

    &-FilterList {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin: 0 auto;
    }

    &-FilterAll,
    &-FilterLetter {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        padding: 16px;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 55px;
        border: 1px solid $color-neutral-30;
        color: $color-neutral-50;
        cursor: pointer;
        transition: .15s ease-in-out;
        margin-bottom: 0;
        pointer-events: none;

        &::selection {
            background-color: transparent;
        }

        &:hover {
            background-color: $color-neutral-20;
        }

        &::before {
            display: none;
        }

        &.FilterLetterAvailable {
            color: $color-neutral-90;
            pointer-events: all;

            &:active {
                background-color: $color-primary-50;
                color: $color-neutral-5;
            }

            &_isActive {
                background-color: $color-primary-50;
                color: $color-neutral-5;
            }
        }
    }

    &-FilterAll {
        width: 109px;
        color: $color-neutral-90;
        pointer-events: all;

        &_isActive {
            background-color: $color-primary-50;
            color: $color-neutral-5;

            &:hover {
                color: $color-neutral-90;
            }
        }
    }

    &-FilterWrapper {
        padding: 0 12px;
    }

    &-ListWrapper {
        margin-top: 56px;
        margin-bottom: 56px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        row-gap: 32px;

        .BrandsPage-BrandCard {
            width: 50%;
        }
    }

    &-ImgWrapper {
        margin-bottom: 16px;
        height: 80px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 24px;
        margin-right: 24px;

        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            color: transparent;
            z-index: 1;

            &::-moz-selection {
                background: transparent;
            }

            &::selection {
                background: transparent;
            }
        }
    }

    &-BrandCard {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            font-size: 12px;
            line-height: 18px;
            cursor: pointer;
        }
    }

    .NoBrandImage {
        opacity: 0;
    }
}

@include tabletAndAbove {
    .BrandsPage {
        margin-top: 56px;

        .Heading_type_large {
            font-size: 30px;
            line-height: 42px;
        }

        .Heading_type_medium {
            font-size: 24px;
            line-height: 34px;
        }

        &-SearchWrapper {
            padding: 65px 74px 100px;
        }

        &-SearchBox {
            width: 576px;
        }

        &-PopularWrapper {
            margin-top: 64px;
            padding-bottom: 64px;
        }

        &-ListWrapper {
            margin-top: 64px;
            margin-bottom: 64px;

            .BrandsPage-BrandCard {
                width: 33.33%;
            }
        }

        &-BrandCard {
            a {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

@include desktopAndAbove {
    .BrandsPage {
        &-SearchBox {
            width: 750px;
        }

        &-PopularWrapperInner {
            justify-content: center;
        }

        &-ListWrapper {
            .BrandsPage-BrandCard {
                width: 16.66%;
            }
        }
    }
}
