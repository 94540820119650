@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.StyleGuide {
    min-height: 700px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    &-Section {
        margin-bottom: 80px;
    }

    &-Title {
        margin-bottom: 20px;
    }

    &-Content {
        margin-bottom: 20px;
        margin-left: 20px;
    }
}
