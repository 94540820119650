@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Popup {
    &-Header {
        padding-right: 30px;
    }

    &-Heading {
        font-size: 20px;
        font-weight: 400;
        line-height: 27px;
    }

    &-Content {
        border-radius: 0;
        max-height: 90%;
    }
}

@include mobile {
    .Popup {
        &-Header {
            display: block;
        }

        .Popup-CloseBtn {
            top: 0;
        }

        &-Heading {
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
        }
    }
}

@include tabletAndAbove {
    .Popup {
        &-Content {
            padding: 32px;
        }
    }
}
