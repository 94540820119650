.Banner {
    &_type_twoTiles {
        margin-bottom: 30px;

        .Banner {
            &-Title {
                text-align: center;
            }

            &-Item {
                overflow: hidden;
                flex: 0 0 48.9%;
                height: 440px;
                margin-bottom: 24px;

                &:hover {
                    img {
                        transform: scale(1.02);
                    }
                }

                a {
                    display: block;
                    height: 100%;
                }
            }

            &-Bar {
                position: absolute;
                left: 0;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                background: $white-transparent;
                padding: 24px;
            }

            &-Header {
                margin-bottom: 16px;
                margin-top: 0;
            }

            &-Text {
                color: $color-neutral-90;
                margin-bottom: 16px;
            }
        }

        img {
            object-fit: cover;
            display: block;

            @include default-transition(transform);
        }
    }
}

@include mobile {
    .Banner {
        &_type_twoTiles {
            .Banner {
                &-Title,
                &-Header {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }
    }
}

@include desktopAndAbove {
    .Banner {
        &_type_twoTiles {
            margin-bottom: 30px;

            .Banner {
                &-Title {
                    text-align: center;
                }

                &-Items {
                    display: flex;
                    justify-content: space-between;
                }

                &-Item {
                    margin-bottom: 0;
                }
            }
        }
    }
}
