@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.BlogPostDetails {
    &-Content {
        overflow: hidden;

        .CatalogWidget {
            z-index: 1;
            margin-top: 40px;
            margin-bottom: 40px;

            @include desktopAndAbove {
                margin-left: 43px;
                margin-right: 43px;
            }

        }

        p:not(.ProductPrice) {
            line-height: 2;
        }

        .Image-Image {
            position: relative;
            width: auto;
            height: auto;
        }

        a {
            color: #63b69e;

            &:hover {
                color: #0a0903;
            }
        }
    }

    .ContentWrapper {
        max-width: 1440px;
        margin: auto;
        padding-left: 24px;
        padding-right: 24px;
    }

    &-Wrapper {
        padding-bottom: 30px;

        @include mobile {
            padding-top: 5px;
        }
    }

    &-ColumnWrapper {
        @include desktopAndAbove {
            display: flex;
        }
    }

    &-Description {
        @include mobile {
            order: 1;
        }

        .Image_ratio_square {
            padding-bottom: 0;
        }

        .Image-Content {
            position: relative;
        }

        .PostDetails-MediaGallery {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);

            @include narrow-desktop() {
                grid-template-columns: repeat(3, 1fr);
            }

            @include wide-desktop() {
                grid-template-columns: repeat(3, 1fr);
            }

            .Image {
                border: solid grey 1px;
                padding: 30%;
                margin: auto;
            }
        }
    }

    &-Title {
        text-align: left;
        font-style: normal;
        font-size: 3.2rem;
        line-height: 4rem;
        margin-top: 0;
        margin-bottom: 2rem;
        font-weight: 500;
    }

    &-InfoWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3rem;
    }

    &-Date,
    &-Author,
    &-ViewCountBox {
        display: flex;
        color: #797979;
        margin-bottom: 2rem;
        align-items: center;

        >svg {
            margin-right: 1rem;
        }
    }

    &-ContentPlaceholder {
        &::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-DatePlaceholder {
        &::after {
            content: 'aaaa/aa/aa';
        }
    }

    &-Iframe {
        margin: 0 auto 1.2rem;
        padding: 56.25% 0 0 0;
        position: relative;

        iframe {
            height: 100% !important;
            left: 0;
            position: absolute;
            top: 0;
            width: 100% !important;
        }
    }

    .slick-dots {
        li {
            background-color: #9B2F34;
        }
    }

    .slick-slider .slick-dots .slick-active {
        background-color: #9B2F34;
    }

    &-Description {
        width: 100%;

        @include desktopAndAbove {
            margin-right: 3rem;
            width: 75%;
        }

        .Image:not(.ProductCard-Picture) {
            height: 100%;
            padding-bottom: 0;
            margin-bottom: 24px;

            > img {
                position: relative;
                object-fit: cover;
                object-position: top;
            }
        }
    }

    &-Info {
        background: #F9F9F9;
        width: 100%;
        margin: 4rem 0;
        padding: 4rem;

        @include desktopAndAbove {
            width: 50%;
        }

        li {
            color: #63b69e;
            margin-bottom: 1rem;

        }

        a {
            cursor: pointer;
            text-decoration: underline;
            color: #63b69e;

        }
    }

    &-InfoTitle {
        font-weight: 500;
        font-size: 2rem;
        margin-bottom: 3rem;

    }

    .Blog-Sidebar {
        width: 100%;

        @include desktopAndAbove {
            width: 25%;
        }
    }

    &-Box {
        margin-bottom: 6rem;
    }

    &-BoxImage {
        margin-bottom: 2rem;
    }

    &-BoxTitle {
        margin: 4rem 0 2rem;
        font-weight: 500;
    }

    &-Tag {
        background: #63b69e;
        color: #fff;
        padding: 1rem 2rem;
        text-transform: uppercase;
        display: inline-flex;
        font-weight: 500;
        margin: 1.5rem 1.5rem 0 0;
    }
}
