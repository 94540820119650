@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NewsletterSubscription {
    .FieldForm-Body {
        display: flex;
    }

    .FieldForm-Fields {
        flex: 1;
    }

    .Field-Wrapper {
        &_type_email {
            margin-bottom: 30px;
        }

        &_type_checkbox {
            span {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0;
                display: inline;

                a {
                    color: $color-primary-70;
                    letter-spacing: 0;
                }
            }
        }
    }

    .Field-CheckboxLabel {
        &Content {
            cursor: initial;
        }

        &:not([class*='_isDisabled']) {
            &:hover {
                color: $color-neutral-70;
            }
        }
    }

    .Button {
        padding-left: 0;
        padding-right: 0;
        width: 105px;
        font-size: 12px;
        height: 48px;
        line-height: 48px;
        position: absolute;
        top: 0;
        right: 0;
    }

    input {
        background: $color-neutral-20;
        font-size: 12px;
        padding-top: 15px;
        padding-bottom: 15px;

        &[type="checkbox"] {
            width: 20px;
            height: 20px;
        }
    }

    &-LabelSwitcher {
        color: $color-neutral-90;
        padding-left: 5px;
        cursor: pointer;
    }
}

@include tabletAndAbove {
    .NewsletterSubscription {
        .Button {
            width: 140px;
        }
    }
}
