@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PasswordStrength {
    width: 100%;
    max-width: 250px;

    &-Bar {
        height: 10px;
        margin-bottom: 9px;

        &_strength_weak {
            background-color: $color-secondary-50;
        }

        &_strength_good {
            background-color: $color-other-3;
        }

        &_strength_strong {
            background-color: $color-primary-50;
        }
    }

    &-Label {
        font-size: 13px;
        line-height: 18px;
        color: $color-primary-70;

        &_isResult {
            color: $color-neutral-90;
            padding-left: 5px;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
}
