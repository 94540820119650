@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ForgotPassword {
    &-InnerWrapper {
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: $color-neutral-70;
        }

        button {
            width: 100%;
        }

        > div {
            margin: 0;
        }
    }

    &-SignInWrapper {
        margin: 0 0 100px;
    }

    .ContentWrapper {
        @include page-container;
    }
}

@include mobileAndTablet {
    .ForgotPassword {
        &-InnerWrapper {
            display: block;

            > div {
                margin: 0 0 64px;
            }

            p + button {
                margin-top: 28px;
            }

            button {
                margin-top: 48px;
            }
        }

        &-SignInWrapper {
            margin-bottom: 64px;
        }

        .ContentWrapper {
            padding-bottom: 0;
        }
    }
}

@include desktopAndAbove {
    .ForgotPassword {
        &-InnerWrapper {
            p {
                min-height: 42px;
            }

            button {
                margin-top: 40px;
            }
        }

        &-ContainerWrapper,
        &-AdditionalContent {
            max-width: 560px;
        }

        .ContentWrapper {
            padding-bottom: 100px;
        }
    }
}
