@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CarouselScrollItem {
    display: flex;
    scroll-snap-align: start;
    transition: border-color var(--animation-speed), filter var(--animation-speed);
    filter: opacity(.5);
    flex: 1;
    cursor: pointer;
    width: 85px;
    height: 68px;

    & + .CarouselScrollItem {
        margin-inline-start: var(--carousel-scroll-gap);
    }

    &_isActive {
        filter: opacity(1);
        border-color: var(--option-border-color);
    }

    &:focus,
    &:hover {
        filter: opacity(1);
    }
}
