@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --overlay-background: #0003;
}

.Overlay {
    &_isVisible {
        + .Overlay-Background {
            display: block;
        }
    }

    &-Background {
        background: var(--overlay-background);
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: none;
        z-index: 1;
    }
}
