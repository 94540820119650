@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MobilePopup {
    background: $color-neutral-5;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 101;
    opacity: 0;
    top: 0;
    left: -100%;

    @include default-transition(all, .2s);

    &_visible {
        opacity: 1;
        visibility: visible;
        left: 0;
        max-width: 100vw;
    }

    &-TopBar {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 23px 20px;
        font-size: 20px;
        color: $color-neutral-5;
        background: $color-neutral-90;

        .TypographyHeader {
            color: $color-neutral-5;
            margin-bottom: 0;
            line-height: 1.2;
        }
    }

    &-Title {
        display: flex;
        gap: 15px;
    }

    &-Content {
        display: block;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        height: calc(100% - 64px);
        overflow-y: scroll;

        &_noSpacing {
            padding: 0;
        }
    }

    &-CloseBtn {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        svg {
            display: block;
        }
    }

    .Field {
        margin-bottom: 24px;

        &-LabelContainer {
            display: none;
        }
    }

    .Button:not(.Button_likeLink) {
        width: 100%;
    }

    .MyAccountOverlay {
        &-Additional {
            text-align: center;

            section {
                justify-content: center;
            }
        }
    }

    button.Button_likeLink {
        color: $color-primary-50;
        font-size: 15px;
    }
}

@include tablet {
    .MobilePopup {
        &-TopBar {
            padding: 15px 20px;

            .TypographyHeader {
                font-size: 20px;
                line-height: 1.7;
            }
        }
    }
}
