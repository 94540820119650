@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Accordion {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        margin: 0;
    }

    &-Header {
        margin: 0;
        cursor: pointer;

        &::after {
            position: absolute;
            top: 0;
            right: -13px;
            content: url('../../assets/icons/plus.svg');
            transform: rotate(180deg);
            line-height: 20px;
            transition: content 1s ease-in-out;
        }
    }

    &-Text {
        overflow: hidden;
        max-height: 0;
        transition: max-height 1s ease-in-out;

        > *:first-of-type {
            margin-top: 25px;
        }
    }

    &-Box {
        padding: 16px;
        text-align: left;
        border-bottom: 1px solid $color-neutral-40;

        &:first-of-type {
            border-top: 1px solid $color-neutral-40;
        }

        &_isOpen {
            .Accordion-Text {
                max-height: 1000px;
            }

            .Accordion-Header {
                &::after {
                    content: url('../../assets/icons/minus.svg');
                }
            }
        }
    }
}

@include tabletAndAbove {
    .Accordion {
        &[data-device="mobile"] {
            flex-direction: row;
            width: auto;

            .Accordion-Box {
                border: 0;
                height: auto;
            }

            .Accordion-Header {
                &::after {
                    display: none;
                }
            }
        }
    }
}

@include desktopAndAbove {
    .Accordion {
        &[data-device="mobileAndTablet"] {
            flex-direction: row;
            width: auto;

            .Accordion-Box {
                border: 0;
                height: auto;
            }

            .Accordion-Text {
                max-height: 10000px;
            }

            .Accordion-Header {
                &::after {
                    display: none;
                }
            }
        }
    }
}
