@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.BrandDetails {
    margin-bottom: 40px;

    .Image-Content {
        margin: 15px 0;
    }

    &-Description {
        strong {
            color: $color-neutral-90;
            font-weight: 400;
        }

        img {
            width: auto;
            height: auto;
        }
    }

    h2:not(.TypographyHeader) {
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 20px;
        font-weight: 400;
        color: $color-neutral-90;

        span {
            font-size: inherit;
        }
    }

    h3 {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 16px;
        font-weight: 400;
        color: $color-neutral-90;
        top: 0;

        span {
            font-size: inherit !important;
        }
    }

    p, li {
        font-size: 14px;
        line-height: 21px;
        color: $color-neutral-50;
    }
}

@include mobileAndTablet {
    .BrandDetails {
        &-Description {
            img {
                display: none;
            }
        }
    }
}
