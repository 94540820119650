@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Menu {
    &-ItemList {
        display: flex;
        justify-content: center;
    }

    &-Item {
        padding-left: 2.9%;
        margin-bottom: 0;
        letter-spacing: 0.7px;

        &:first-child {
            padding-left: 0;
        }

        &::before {
            display: none;
        }
    }

    &-SubCategoriesWrapper {
        display: none;

        &_isVisible {
            display: block;
        }
    }
}

@include desktopAndAbove {
    .Menu-ItemList_type_main .Menu-Link {
        .Menu-ItemCaption {
            position: relative;
            padding: 0 0 17px;

            &::before {
                content: '';
                position: absolute;
                left: -20px;
                bottom: 0;
                height: 3px;
                width: calc(100% + 40px);
                background-color: $color-primary-50;
                opacity: 0;
                transition: .3s ease-in-out;
                z-index: 21;
            }
        }

        &_isHovered {
            .Menu-ItemCaption::before {
                opacity: 1;
            }
        }
    }

    .Menu-SubCategories {
        width: 1436px;
        padding: 0 30px;
        max-width: 100%;

        &Wrapper {
            width: 100vw;
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            top: 186px;
            z-index: 20;
            border-top: 2px solid $color-neutral-30;

            &::before {
                content: '';
                width: 100vw;
                height: 100vh;
                position: fixed;
                right: 0;
                top: 0;
                background-color: $color-neutral-90;
                z-index: -1;
                opacity: 0.4;
                transition: .5s;
                pointer-events: none;
            }

            &Inner {
                width: 100vw;
                max-width: 100%;
                background-color: $color-neutral-5;
                display: flex;
                justify-content: center;
            }
        }
    }

    .Menu-SubItemWrapper {
        min-width: 100%;
    }

    .Menu-ItemList_type_subcategory {
        max-width: 1436px;
        padding: 40px 0 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        grid-column-gap: 10px;

        .Menu-ItemList {
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;
            width: 100%;
            max-width: 100%;
        }
    }

    .Menu-ItemCaption_type_subcategory {
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 21px;
    }

    .Menu-SubLevelDesktop {
        .Menu-Link {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 15px;
            color: $color-neutral-70;

            &:last-of-type {
                margin-bottom: 0;
            }

            &:hover {
                color: $color-neutral-90;
                text-decoration: underline;
            }
        }
    }
}
