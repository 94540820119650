@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --footer-background: #{$color-neutral-20};
}

.Footer {
    background: var(--footer-background);

    &-Column {
        &_isNewsletter {
            background: $color-neutral-5;
            padding: 30px 18px;

            .Footer-ColumnTitle {
                font-size: 15px;
                line-height: 21px;
                margin-top: 0;
            }

            .Field-CheckboxLabelContent {
                font-size: 12px;
            }
        }
    }

    &-ColumnTitle {
        font-style: normal;
        font-weight: normal;
        color: $color-neutral-90;
        font-size: 16px;
        line-height: 27px;
    }

    &-ColumnSubtitle {
        font-size: 12px;
        line-height: 18px;
        color: $color-neutral-70;
        margin-bottom: 40px;
    }

    .CmsBlock-Wrapper {
        width: 100%;
    }

    &-Contact {
        text-align: center;
        margin: 50px 0 25px;
    }

    &-Logo {
        margin: 5px;

        img {
            max-width: 55px;
        }
    }

    &-ContactItem {
        color: $color-neutral-70;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        padding: 18px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;

        svg {
            margin-right: 18px;
            max-width: 23px;
            max-height: 23px;
        }

        a {
            color: $color-neutral-70;
            text-decoration: none;
            font-weight: normal;
        }
    }

    &-SocialHeader {
        font-size: 20px;
        line-height: 27px;
        color: $color-neutral-90;
    }

    &-Social {
        display: flex;
        margin: 20px auto 58px;
        justify-content: space-between;
        max-width: 300px;

        a {
            flex: 0 0 auto;
            margin-right: 32px;

            &:last-child {
                margin-right: 0;
            }
        }

        svg {
            height: 33px;
            width: 33px;

            &.Footer-Twitter {
                width: 39px;
            }
        }
    }

    ul {
        li {
            padding: 0;
            margin-bottom: 30px;

            &::before {
                display: none;
            }

            &:last-of-type {
                margin-bottom: 7px;
            }
        }
    }

    a {
        color: $color-neutral-70;
        text-decoration: none;
        font-weight: normal;
        letter-spacing: 0.05em;
    }

    h4 {
        font-size: inherit;
        line-height: inherit;
        margin: 0;
    }

    &-CopyrightContent {
        padding: 56px 0;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: $color-neutral-70;

        a {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0;
        }
    }

    &-Copyright,
    &-PoweredBy {
        display: block;
    }

    &-ScrollTopWrapper {
        position: relative;
    }

    &-ScrollTop {
        background: $color-neutral-40;
        width: 100%;
        height: 72px;
        cursor: pointer;

        svg {
            width: 40px;
        }
    }
}

@include tabletAndAbove {
    .Footer {
        .ContentWrapper {
            padding-left: 72px;
            padding-right: 72px;
        }

        &-Column {
            &_isNewsletter {
                padding: 50px 100px;
            }
        }

        &-ColumnSubtitle {
            margin-bottom: 16px;
        }


        &-ContactItems {
            display: flex;
            justify-content: space-between;
        }

        &-Social {
            margin-left: 0;
        }

        &-CopyrightContent {
            display: flex;
            justify-content: space-between;
            text-align: left;
        }
    }
}

@include desktopAndAbove {
    .Footer {
        .ContentWrapper {
            padding-left: 0;
            padding-right: 0;

            @include page-container;
        }

        &-Content {
            &::before {
                background: $color-neutral-5;
                position: absolute;
                content: '';
                right: 0;
                top: 0;
                width: calc(((100vw - var(--content-wrapper-width)) / 2) + 120px);
                height: 100%;
                display: block;
            }
        }

        &-Columns {
            display: flex;
            justify-content: space-between;
        }

        &-Column {
            &_isNewsletter {
                order: 2;
                padding: 88px 40px 80px 40px;
                flex: 0 0 auto;
                width: 480px;

                .Footer-ColumnTitle {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }

        &-ColumnTitle {
            font-size: 14px;
            line-height: 21px;
            margin: 0 0 16px;
            letter-spacing: 0.05em;
        }

        &-Accordion {
            display: flex;
            flex-direction: row;
            padding: 88px 25px 80px 0;
            justify-content: space-around;

            .Footer-Column {
                padding: 0;
            }
        }

        &-ContactWrapper {
            display: flex;
            justify-content: space-between;
        }

        &-Contact {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 65px;
        }

        &-Logo {
            margin: 0;
        }

        &-ContactItem {
            margin-left: 40px;
            font-size: 12px;
            line-height: 18px;
        }

        &-SocialWrapper {
            margin-right: 34px;
        }

        &-SocialHeader {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: $color-neutral-70;
            margin: 48px 0 36px;
            display: block;
        }

        &-Social {
            margin-bottom: 50px;

            svg {
                height: 18px;
                width: 18px;

                &.Footer-Twitter {
                    width: 22px;
                }
            }
        }

        ul {
            li {
                margin-bottom: 16px;
            }
        }

        a {
            font-size: 12px;
            line-height: 18px;
        }

        &-CopyrightContent {
            padding: 40px 0;
            border-top: 1px solid $color-neutral-40;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.05em;

            a {
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.05em;
            }
        }

        &-ScrollTop {
            position: absolute;
            width: 40px;
            height: 40px;
            right: 5px;
            top: -28px;
            z-index: 1;
            box-sizing: content-box;
            border: 10px solid var(--footer-background);

            svg {
                width: 15px;
            }
        }
    }
}

@include above1200 {
    .Footer {
        &-Column {
            &_isNewsletter {
                padding: 88px 0 80px 120px;
                width: 560px;
            }
        }

        &-Accordion {
            padding: 88px 88px 80px 0;
            justify-content: space-between;
        }
    }
}

@include above1570 {
    .Footer {
        &-ScrollTop {
            right: -73px;
        }
    }
}
