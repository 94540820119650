@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SliderNextArrow {
    right: 10px;
    cursor: pointer;
    position: absolute;
    bottom: -14px;

    &_disabled {
        svg {
            opacity: 0.4;
        }
    }

    svg {
        @include default-transition(left, .2s);

        left: 0;
    }

    &:hover {
        svg {
            left: 4px;
        }
    }
}

@include desktopAndAbove {
    .SliderNextArrow {
        position: absolute;
        bottom: 50%;
        z-index: 1;
        transform: translateY(50%);
    }
}

@include above1480 {
    .SliderNextArrow {
        right: -44px;
    }
}
