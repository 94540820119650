@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Breadcrumbs {
    @include page-container;

    &-List {
        list-style: none;
        padding: 16px 0;
    }

    .ContentWrapper {
        padding: 0;
    }
}

@include mobileAndTablet {
    .Breadcrumbs {
        &-List {
            overflow-x: auto;
            white-space: nowrap;
        }
    }
}
