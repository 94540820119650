@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AutoRelatedSlider {
    margin: 50px 0 80px;

    &-Item {
        padding: 0 25px 0 0;
    }

    &-Loader {
        height: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@include mobile {
    .AutoRelatedSlider {
        &-Item {
            width: 145px !important; //Needed to override inline JS-generated style
        }
    }
}

@include tablet {
    .AutoRelatedSlider {
        &-Item {
            width: 350px !important; //Needed to override inline JS-generated style
        }

        &-Loader {
            height: 442px;
        }
    }
}

@include mobileAndTablet {
    .AutoRelatedSlider {
        margin: 40px -20px 20px 0
    }
}

@include desktopAndAbove {
    .AutoRelatedSlider {
        &-Item {
            padding: 0 10px 0;
        }

        &-Loader {
            height: 445px;
        }

        .slick-slider {
            margin-left: -10px;
            margin-right: -10px;
        }

        .slick-list {
            overflow: visible;
        }

        .SliderPrevArrow, .SliderNextArrow {
            height: 100%;
            bottom: 0;
            transform: none;
            display: flex;
            align-items: center;
            width: 35px;
            background: $color-neutral-5;
        }

        .SliderPrevArrow {
            left: -34px;
        }

        .SliderNextArrow {
            justify-content: flex-end;
            right: -34px;
        }
    }
}
