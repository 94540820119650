@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.Blog-Sidebar {
    .BlogCategories {
        &-Wrapper {
            margin-top: 2rem;
        }

        &-Category {
            span {
                padding-left: .75rem;
            }

            .BlogCategoryTitle {
                font-weight: normal;
                color: $color-primary-50;
            }
        }
    }
}