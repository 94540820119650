.InfoTiles {
    margin-bottom: 50px;

    &-Item {
        background: $color-neutral-10;
        margin-bottom: 24px;

        &:last-child {
            margin-right: 0;
        }
    }

    &-Title {
        margin-top: 0;
    }

    &-Icon {
        margin-bottom: 20px;
    }

    &-Text {
        color: $color-neutral-50;
    }
}

@include mobile {
    .InfoTiles {
        &-Item {
            padding: 36px 24px 32px;
        }

        &-Title {
            font-size: 16px;
            line-height: 24px;
            padding-left: 66px;
            margin-bottom: 23px;
        }

        &-Icon {
            position: absolute;
            transform: scale(0.85);
            left: 24px;
            top: 30px;
        }
    }
}

@include tabletAndAbove {
    .InfoTiles {
        &-Items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-Item {
            padding: 32px;
            width: 48.2%;
        }
    }
}

@include desktopAndAbove {
    .InfoTiles {
        &-Item {
            margin-right: 24px;
            flex: 1;
            width: auto;
            margin-bottom: 0;
        }

        &-Title {
            margin-bottom: 12px;
        }
    }

    .CmsBlock-Wrapper {
        &_type_category {
            .InfoTiles {
                &-Items {
                    display: block;
                }

                &-Item {
                    margin: 0 0 15px 0;
                    padding: 24px;
                }

                &-Title {
                    font-size: 16px;
                }

                &-Text {
                    font-size: 12px;
                }
            }
        }
    }
}
