@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

:root {
    --date-text-color: #797979;
    --tag-text-color: #fff;
}

.BlogPostCart {
    font-size: 12px;
    text-align: left;
    width: 100%;
    margin-bottom: 4rem;

    &_isLoading {
        padding: 80% 0 0;
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        box-shadow: var(--attraction-shadow);
    }

    &-DateWrapper {
        display: flex;
        align-items: center;
    }

    &-Details {
        .PostsDetails {
            &-Date {
                color: var(--date-text-color);
                font-size: 1.3rem;
                text-align: left;
                padding-left: 1rem;
            }
        }
    }

    &-Title {
        font-size: 2.5rem;
        font-weight: 500;
        margin: 2rem 0 1rem;
    }

    &-Description {
        margin-top: 2rem;
        line-height: 32px;
        font-size: 16px;
        color: $color-neutral-80;

        > p {
            line-height: 32px;
            font-size: 16px;
            color: $color-neutral-80;
        }
    }


    &-Image {
        height: 100%;
        padding-bottom: 0;
        max-height: 350px;
        display: block;

        > img {
            max-height: 350px;
            position: relative;
            object-fit: cover;
            object-position: top;
            display: block;
        }
    }

    &-ImageWrapper {
        flex-basis: 40%;
    }

    &-DateWrapper {
        display: flex;
        color: var(--date-text-color);
        margin-bottom: 2rem;
        align-items: center;

        >svg {
            margin-right: 1rem;
        }
    }

    &-ImageBox {
        position: relative;
    }

    &-Tag {
        background: $color-primary-50;
        color: var(--tag-text-color);
        padding: 1rem 2rem;
        text-transform: uppercase;
        display: inline-flex;
        font-weight: 500;
        margin: 8px 8px 0 0;
        justify-content: center;
    }

    &-TagsBox {
        position: absolute;
        z-index: 1;
        bottom: 8px;
        left: 8px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
}

@include mobile {
    .BlogPostCart {
        &-Image {
            img {
                height: 180px;
            }
        }
    }
}


@include tablet {
    .BlogPostCart {
        &-Image {
            img {
                height: 164px;
            }
        }
    }
}

@include tabletAndAbove {
    .BlogPostCart {
        &-TagsBox {
            flex-direction: row;
        }

        &:not(:first-of-type) {
            margin-bottom: 0;

            .BlogPostCart-ContentWrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 2rem;
            }

            .BlogPostCart-ImageBox {
                flex: 0 0 40%;
            }

            .BlogPostCart-Details {
                flex: 0 0 55%;
            }

            .BlogPostCart-Title {
                margin-top: 0;
            }
        }
    }
}

@include desktopAndAbove {
    .BlogPostCart {
        &-Tag {
            margin: 16px 16px 0 0;
        }

        &-TagsBox {
            bottom: 24px;
            left: 24px;
        }
    }
}
