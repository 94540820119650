@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MobilePopup {
    &-Title {
        align-items: center;
    }

    &-Content {
        z-index: 11;

        .Menu-MenuWrapper {
            overflow-y: scroll;
            height: calc(100% - 47px);
        }

        .Menu-ItemList_type_main {
            display: flex;
            flex-direction: column;

            .Menu {
                &-Item {
                    padding-left: 0;
                    cursor: pointer;

                    &Caption {
                        transition: .3s ease-in-out;

                        &:hover {
                            color: $color-primary-50;
                        }
                    }
                }

                &-SubCatLink {
                    &_inner {
                        display: flex;

                        .Menu-Link {
                            width: 100%;
                        }

                        .Menu-ItemCaption_type_main,
                        .Menu-ItemCaption_type_subcategory {
                            max-width: 80px;
                            display: flex;
                            justify-content: flex-end;
                            font-size: 0;
                            letter-spacing: -12px;
                            height: 100%;
                            padding: 0 22px 0 20px;

                            &:hover {
                                background-color: $color-neutral-20;
                            }
                        }
                    }
                }
            }
        }

        .Menu-ItemCaption {
            padding: 14px 30px 14px 32px;
            border-bottom: 1px solid $color-neutral-30;
            font-size: 13px;
            line-height: 19.5px;
            max-width: 100%;

            &_type {
                &_main,
                &_subcategory {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }

        .Menu-ItemList_type_main_isActive {
            .Menu-Item {
                display: none;

                &_isActive {
                    display: block;

                    .Menu-SubCatLink_inner {
                        flex-direction: row-reverse;
                    }

                    .Menu-Link.Menu-Link_isHovered {
                        pointer-events: none;

                        figcaption {
                            padding-left: 0;
                        }
                    }

                    .Menu-ItemCaption {
                        &_isExpanded {
                            padding-left: 44px;

                            &:hover {
                                background-color: transparent;
                            }

                            &::before {
                                content: '';
                                position: absolute;
                                width: 10px;
                                height: 2px;
                                top: 50%;
                                left: 36px;
                                transform: translateY(-50%);
                                background-color: $color-neutral-90;
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                width: 8px;
                                height: 8px;
                                top: 50%;
                                left: 34px;
                                transform: translateY(-50%) rotate(135deg);
                                border: solid $color-neutral-90;
                                border-width: 0 2px 2px 0;
                                box-sizing: border-box;
                            }

                            .Menu-ExpandedState_isExpanded {
                                display: none;
                            }
                        }

                        &_isSecondLevel {
                            .Menu-ExpandedState {
                                display: block;
                                transform: rotate(45deg);
                            }

                            &.Menu-ItemCaption_isExpanded {
                                padding-left: 32px;

                                &::before,
                                &::after {
                                    display: none;
                                }

                                .Menu-ExpandedState_isExpanded {
                                    display: block;
                                    transform: rotate(-135deg);
                                }
                            }
                        }
                    }
                }
            }
        }

        .Menu-SubMenu_isVisible .Menu-SubMenu_isVisible .Menu-ItemCaption_type_subcategory {
            padding-left: 54px;
            background-color: $color-neutral-20;
        }

        .Menu-ExpandedState {
            width: 8px;
            height: 8px;
            overflow: hidden;
            position: relative;
            transform: rotate(-45deg);

            svg {
                display: none;
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border: solid $color-neutral-90;
                border-width: 0 2px 2px 0;
                box-sizing: border-box;
            }
        }

        .Menu-ItemList.Menu-ItemList_type_subcategory {
            display: flex;
            flex-direction: column;
            width: 100%;

            .Menu-ItemCaption_isSecondLevel.Menu-ItemCaption_isExpanded {
                padding-left: 22px;
            }

            .Menu-SubCatLink_inner {
                flex-direction: row;
            }

            .Menu-Link.Menu-Link_isHovered {
                figcaption {
                    padding-left: 32px;
                }
            }
        }

        .Menu-SubMenu {
            display: none;

            &_isVisible {
                display: flex;
            }
        }
    }

    .InfoBar-Wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

@include tabletAndAbove {
    .MobilePopup-Content {
        .Menu-ItemCaption {
            padding: 16px 22px 16px 21px;
        }

        .MobilePopup-CloseBtn {
            right: 24px;
        }

        .Menu-ItemList_type_main_isActive {
            .Menu-Item {
                display: none;

                &_isActive {
                    display: block;

                    .Menu-ItemCaption {
                        &_isExpanded {
                            padding-left: 31px;

                            &::before {
                                left: 24px;
                            }

                            &::after {
                                left: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}
