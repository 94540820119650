.Banner {
    &_type_singleTile {
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.02);
            }
        }

        p {
            margin-bottom: 0;
        }

        a {
            display: block;
            height: 100%;
        }

        img {
            display: none;

            @include default-transition(transform);
        }
    }

    &_layout {
        &_grid {
            .Banner {
                &_type_singleTile {
                    .Banner {
                        &-Title {
                            text-align: center;
                        }

                        &-Bar {
                            position: absolute;
                            left: 0;
                            width: 100%;
                            bottom: 0;
                            text-align: center;
                            background: $white-transparent;
                            padding: 24px 30px;
                        }

                        &-Header {
                            margin-bottom: 20px;
                        }
                    }
                }

                &-Image_type_grid {
                    display: block;
                }
            }
        }

        &_list {
            margin-bottom: 30px;

            .Banner {
                &_type_singleTile {
                    height: 240px;

                    .Banner {
                        &-Title {
                            text-align: center;
                        }

                        &-Bar {
                            max-width: 340px;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            bottom: 0;
                            text-align: center;
                            background: $white-transparent;
                            padding: 0 30px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }

                        &-Header {
                            margin-bottom: 20px;
                        }
                    }
                }

                &-Image_type_list {
                    display: block;
                }
            }
        }
    }
}
