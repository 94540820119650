@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Tag {
    font-size: 12px;
    line-height: 17px;
    padding: 8px 18px 7px;
    background: $color-neutral-20;
    color: $color-neutral-50;
    display: inline-block;
    transition: background-color, color .25s ease-out;
    transition-duration: .25s;
    transition-timing-function: ease-out;
    font-weight: 500;

    &:hover {
        background: $color-neutral-90;
        color: $color-neutral-5;
        cursor: pointer;
    }
}
