@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.InstallPromptIOS {
    background: var(--secondary-light-color);
    padding: 24px;

    &-Heading {
        margin-bottom: 16px;
        text-align: center;
    }

    &-Content {
        align-items: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 0;

        span {
            margin-bottom: 10px;
        }
    }

    &-Close {
        color: var(--secondary-dark-color);
        width: 100%;
        text-align: end;
        text-decoration: underline;
        margin-block-start: 5px;
    }

    &-Plus {
        @include ios-plus;

        transform: scale(.9);
        transform-origin: 50% 50%;
    }

    &-Share {
        @include ios-share;

        transform: scale(.8);
        transform-origin: 50% 50%;
    }

    p {
        line-height: 1;
    }
}
