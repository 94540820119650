:root {
    --link-color: var(--imported_link_color, var(--primary-base-color));
    --link-hover: var(--imported_linkHover_color, var(--primary-dark-color));
    --default-hover-link-underline: 2px solid var(--link-hover);
    --link-underline: var(--imported_link_border_bottom, none);
    --link-hover-underline: var(--imported_linkHover_border_bottom, var(--default-hover-link-underline));
}

a {
    text-decoration: none;
    color: var(--link-color);

    @include default-transition();

    &:hover {
        color: var(--link-hover);
    }

    &.Link {
        &:hover,
        &:focus {
            border-block-end: var(--link-hover-underline);
            padding-block-end: 3px;
        }
    }
}
