@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ReviewStar {
    --star-outline-color: #{$color-other-3};

    // reset from input default styles
    display: inline;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    transition: color 150ms ease-in;
    color: $white;
    text-shadow: -1px 0 var(--star-outline-color),
        0 1px var(--star-outline-color),
        1px 0 var(--star-outline-color),
        0 -1px var(--star-outline-color);
    font-size: 26px;
    line-height: 1;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: Times; // make sure ★ appears correctly
    letter-spacing: 6px;

    &::after {
        content: '★';
        font-family: serif;
    }

    &:checked,
    &:hover,
    &[checked],
    &:checked ~ input,
    &:hover ~ input,
    &[checked] ~ input {
        --star-outline-color: #{$color-other-3};

        color: $color-other-3;
    }

    &:focus {
        border: 0;
    }

    &:first-of-type {
        margin-block-start: 12px;
    }
}
