:root {
    --default-input-padding: 15px 20px 14px;
    --input-background-color: var(--imported_input_background_color, #fff);
    --input-border-color: var(--imported_input_border_color, #e0e0e0);
    --input-border-color-focus: var(--primary-base-color);
    --input-border-radius: var(--imported_input_border_radius, 2px);
    --input-padding: var(--imported_input_padding, var(--default-input-padding));
    --input-color: var(--imported_input_color, var(--color-karaka));
    --input-type-number-height: 46px;
    --input-type-number-width: 28px;
    --input-type-qty-width: 86px;
}

input,
textarea,
select {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    color: var(--input-color);
    padding: var(--input-padding);
    appearance: none;
    caret-color: var(--primary-base-color);

    &:focus {
        border: 1px solid var(--input-border-color-focus);
    }

    &:not(:first-of-type) {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &:-webkit-autofill {
        box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
        background-color: var(--input-background-color) !important;
    }
}

textarea {
    width: 300px;

    @include mobile {
        width: 100%;
    }
}

[type='text'] {
    @include mobile {
        width: 100%;
    }
}

[type='text'], [type='password'], [type='email'], [type='tel'], textarea {
    --input-border-color: #{$color-neutral-20};
    --input-background-color: #{$color-neutral-20};
    --input-padding: 16px 21px 16px 32px;
    --input-color: #{$color-neutral-70};

    border-radius: 0;
    width: 100%;
    letter-spacing: 0.5px;
    line-height: 16px;

    &:focus {
        --input-border-color-focus: #{$color-neutral-90};
        --input-color: #{$color-neutral-90};
        --input-background-color: #{$color-neutral-5};
    }

    &:disabled {
        --input-border-color: #{$color-neutral-10};
        --input-background-color: #{$color-neutral-10};
        --input-color: #{$color-neutral-40};
    }
}

[type='number'] {
    -moz-appearance: textfield;
    color: $color-neutral-90;
    border: 0;
    font-size: 16px;
    font-weight: 400;
    height: var(--input-type-number-height);
    padding: 0;
    order: 2;
    text-align: center;
    width: var(--input-type-number-width);
    pointer-events: none;

    &:focus {
        border: 1px solid var(--input-border-color);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    ~ button {
        background: transparent;
        color: $color-neutral-90;
        inset-inline-start: 0;
        line-height: 0;
        margin: 0;
        order: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        inset-block-start: 0;
        width: 34px;

        &:first-of-type {
            inset-inline-start: 0;
            order: 3;
        }

        &:last-of-type {
            inset-block-end: 0;
            inset-inline-end: 0;
            order: 1;
        }

        &:disabled {
            opacity: .2;
            cursor: not-allowed;
        }

        span {
            @include mobile {
                inset-block-start: -2px;
            }
        }
    }
}
