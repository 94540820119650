@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Notification {
    border-radius: 0;
    line-height: 18px;
    padding: 11px 0 9px 46px;
    width: 100%;
    --notification-height: 40px;

    &_type {
        &_error {
            --notification-error-background: #{$color-secondary-50};
        }

        &_success {
            --notification-success-background: #{$color-primary-50};
        }

        &_info {
            --notification-info-background: #{$color-other-2};
            --notification-info-color: #{$color-neutral-5};
        }
    }

    &-Text {
        font-weight: 400;
        margin-inline-end: 0;
        font-size: 15px;
    }

    &-Button {
        display: none;
    }
}
