@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, .44);
    --search-criteria-search-term-color: #0a0a0a;
}

.SearchOverlay {
    &-Results {
        background: var(--color-white);
        display: block;
        box-shadow: none;
        padding: 15px;

        p {
            margin-top: 24px;
            margin-bottom: 0;
        }

        ul {
            overflow-y: scroll;
            max-height: calc(100vh - 190px);
        }
    }
}

.MobilePopup {
    .SearchOverlay {
        &-Results {
            padding: 34px 0 15px;
        }
    }
}

@include desktopAndAbove {
    .SearchOverlay {
        &-Results {
            position: absolute;
            width: 100%;

            p {
                margin-top: 24px;
                margin-bottom: 0;
            }

            ul {
                @include scrollbar($color-neutral-90);
            }
        }
    }
}
