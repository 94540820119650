@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --checkbox-width: var(--imported_checkboxes_width, 16px);
    --checkbox-height: var(--imported_checkboxes_height, 16px);
    --radio-width: var(--imported_radios_width, 16px);
    --radio-height: var(--imported_radios_height, 16px);
}

.Field {
    margin-top: 0;

    &-Label {
        padding-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
        color: $color-neutral-90;
    }

    &-ErrorMessages {
        font-size: 13px;
        margin-bottom: 0;
        margin-left: 32px;
        margin-top: 8px;
        color: $color-secondary-70;
    }

    input {
        &::placeholder {
            color: $color-neutral-70;
            opacity: 1;
        }

        &:disabled {
            &::placeholder {
                color: $color-neutral-40;
            }
        }

        &[type='tel'] {
            --input-border-color: #{$color-neutral-20};
            --input-background-color: #{$color-neutral-20};
            --input-padding: 16px 21px 16px 32px;
            --input-color: #{$color-neutral-70};
        }
    }

    &_type {
        &_number {
            border: 1px solid $color-neutral-30;
            padding: 0;
            width: var(--input-type-qty-width);

            button {
                height: var(--input-type-number-height);
            }

            svg {
                --plus-minus-icon-size: 18px;

                &:hover {
                    --primary-base-color: #{$color-neutral-90};
                }
            }
        }

        &_text,
        &_password,
        &_select,
        &_checkbox,
        &_email,
        &_tel {
            margin-bottom: 20px;
        }
    }

    &_variant_primary {
        &.Field_hasError {
            [type='text'],
            [type='password'],
            [type='email'],
            [type='tel'],
            textarea {
                --input-color: #{$color-secondary-70};
                --input-background-color: #{$color-secondary-5};

                border: 1px solid $color-secondary-5;
            }
        }

        &.Field_isValid {
            [type='text'],
            [type='password'],
            [type='email'],
            [type='tel'],
            textarea {
                --input-color: #{$color-primary-70};
                --input-background-color: #{$color-primary-5};

                border: 1px solid $color-primary-5;
            }
        }
    }

    &_variant_secondary {
        [type='text'],
        [type='password'],
        [type='email'],
        textarea {
            --input-background-color: transparent;
            --input-padding: 18px 21px 16px;

            border-radius: 0;
            border-width: 0;
            border-bottom-width: 3px;
            --input-border-color: #{$color-neutral-40};

            &:focus {
                --input-border-color-focus: #{$color-primary-50};
                --input-color: #{$color-neutral-90};
            }

            &:disabled {
                --input-border-color: #{$color-neutral-30};
                --input-color: #{$color-neutral-40};
            }
        }

        &.Field_hasError {
            [type='text'],
            [type='password'],
            [type='email'],
            [type='tel'],
            textarea {
                --input-color: #{$color-secondary-50};

                border: 0;
                border-bottom: 3px solid $color-secondary-50;
            }
        }

        &.Field_isValid {
            [type='text'],
            [type='password'],
            [type='email'],
            [type='tel'],
            textarea {
                --input-color: #{$color-primary-50};

                border: 0;
                border-bottom: 3px solid $color-primary-50;
            }
        }
    }

    &_variant_outline {
        [type='text'],
        [type='password'],
        [type='email'],
        textarea {
            --input-background-color: transparent;
            --input-padding: 18px 21px 16px;

            border-radius: 0;
            border-width: 1px;
            --input-border-color: #{$color-neutral-30};

            &:focus {
                --input-border-color-focus: #{$color-primary-50};
                --input-color: #{$color-neutral-90};
            }

            &:disabled {
                --input-border-color: #{$color-neutral-30};
                --input-color: #{$color-neutral-40};
            }
        }
    }

    // Checkbox & Radio

    &-CheckboxLabel,
    &-RadioLabel {
        color: $color-neutral-70;
        transition: color .25s ease-in;
        align-items: start;
        text-align: left;

        &_checked {
            color: $color-primary-50;
        }

        &_disabled {
            color: $color-neutral-50;
        }

        span {
            display: flex;
        }
    }

    &-RadioLabel {
        &:hover {
            cursor: pointer;
        }
    }

    &-CheckboxLabelContent,
    &-RadioLabelContent {
        flex: 1;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
    }

    [type="checkbox"] {
        +.input-control {
            --box-color: #{$color-neutral-30};

            border: 1px solid $color-neutral-30;
            width: var(--checkbox-width);
            height: var(--checkbox-height);
            top: 2px;
            flex: 0 0 16px;

            &_disabled {
                border-color: $color-neutral-30 !important;
                --box-color: #{$color-neutral-30} !important;

                &::after {
                    --checkmark-color: #{$color-neutral-30} !important;
                }
            }
        }

        &:checked {
            +.input-control {
                --box-color: #{$color-primary-50};

                border-color: $color-primary-50;

                &::after {
                    --checkmark-color: #{$color-neutral-5};
                }

                &_disabled {
                    &::after {
                        --checkmark-color: #{$color-neutral-5} !important;
                    }
                }

                +span {
                    color: $color-primary-50;
                }
            }
        }

        &:not(:checked) {
            &:hover {
                + .input-control {
                    --box-color: #{$color-primary-30};

                    border-color: $color-primary-30;

                    &::after {
                        --checkmark-color: #{$color-primary-30};
                    }
                }
            }
        }
    }

    [type='radio'] {
        position: absolute;
        opacity: 0;
        padding: 0;
        min-width: 0;
        min-height: 0;

        +.input-control {
            background-color: $color-neutral-5;
            border: 1px solid $color-neutral-70;
            border-radius: 50%;
            cursor: pointer;
            display: inline-block;
            height: var(--radio-height);
            position: relative;
            padding: 0;
            transition-duration: .25s;
            transition-property: background-color, border-color;
            transition-timing-function: ease-in;
            width: var(--radio-width);
            will-change: background-color, border-color;
            margin-inline-end: 11px;

            &_disabled {
                border-color: $color-neutral-30 !important;
                background-color: $color-neutral-30;
            }
        }

        &:checked {
            +.input-control {
                border-color: $color-primary-50;
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    box-shadow: none;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: $color-primary-50;
                    position: initial;
                }

                &_disabled {
                    border-color: $color-neutral-50;
                    background-color: $color-neutral-5;

                    &::after {
                        background: $color-neutral-50;
                    }
                }
            }
        }

        &:not(:checked) {
            &:hover {
                +.input-control {
                    border-color: $color-neutral-70;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    &-Message {
        --primary-error-color: #{$color-secondary-50};

        margin-bottom: 0;
        padding-left: 21px;
    }
}
