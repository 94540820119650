@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --reset-attributes-value-background: #{$color-neutral-30};
    --reset-attributes-label-color: #{$color-neutral-90};
    --reset-attributes-text-color: #{$color-neutral-90};
    --reset-attributes-icon-color: #{$color-neutral-90};
}

.ResetAttributes {
    &-Attribute {
        margin-right: 10px;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
    }

    &-AttributeValue {
        background: var(--reset-attributes-value-background);
        padding: 7px 36px 7px 12px;
        position: relative;
        cursor: pointer;
        margin: 0 10px 10px 0;


        &:hover {
            .ResetAttributes-CloseIcon {
                transform: rotate(-90deg);
            }
        }
    }

    &-AttributeLabel {
        font-size: 13px;
        font-weight: 400;
        color: var(--reset-attributes-label-color);
        margin-right: 10px;
    }

    &-AttributeText {
        inset-inline-start: 0;
        font-size: 13px;
        font-weight: 600;
        color: var(--reset-attributes-text-color);
    }


    &-CloseIcon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 36px;
        transform: rotate(0);

        @include default-transition(transform, .2s);

        .CloseIcon {
            width: 24px;
            height: 24px;
            fill: var(--reset-attributes-icon-color);
        }
    }
}
