@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Pagination {
    display: flex;
    justify-content: flex-end;

    &-List {
        display: flex;
    }

    &-ListItem {
        padding: 0;
        margin: 0;
        width: 40px;
        height: 40px;

        &::before {
            display: none;
        }

        &:empty {
            display: none;
        }
    }
}
