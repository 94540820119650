@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --header-bg: #{$color-neutral-5};
    --header-actionlabel-color: #{$color-neutral-70};
    --header-actionlabel-hover-color: #{$color-neutral-90};
    --header-mobile-menu-switch-bg: #{$color-neutral-90};
    --header-mobile-menu-switch-lines-bg: #{$color-neutral-5};

    --header-logo-width: 194px;
    --header-logo-height: 24px;

    --header-height: 60px;
    --header-nav-height: 76px;
    --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top));

    @include desktop {
        --header-top-menu-height: 32px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }
}

body {
    padding-top: 64px;
}

.Header {
    z-index: 1;

    &-Wrapper {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 99;
        background: var(--header-bg);
    }

    &-Content {
        @include page-container;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &-Actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: -9px;
    }

    &-Action {
        margin-left: 5px;

        &:first-child {
            margin-right: 0;
        }

        &:hover {
            .Header-ActionLabel {
                color: var(--header-actionlabel-hover-color);
            }
        }
    }

    button.Header-MyAccountWrapper,
    a.Header-Button,
    button.Header-Button,
    &-Button > button {
        padding: 0 6px;
        display: block;
    }

    &-ActionLabel {
        font-size: 12px;
        line-height: 18px;
        color: var(--header-actionlabel-color);
        display: none;

        @include default-transition(color, .2s);
    }

    &-ActionIcon {
        height: 25px;
        text-align: center;
    }

    &-MobileMenuSwitcher {
        width: 32px;
        height: 32px;
        background: var(--header-mobile-menu-switch-bg);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        cursor: pointer;

        span {
            width: 13px;
            height: 2px;
            display: block;
            margin-bottom: 4px;
            background: var(--header-mobile-menu-switch-lines-bg);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-SwitchWithLogo {
        display: flex;
        align-items: center;
    }

    .Logo {
        width: 74px;
        height: 24px;
    }

    &-Menu {
        @include page-container;

        display: none;
    }

    &-MinicartItemCount, &-CompareCount {
        position: absolute;
        top: 9px;
        right: 9px;
        width: 14px;
        height: 14px;
        background: $color-primary-70;
        border-radius: 50%;
        font-size: 8px;
        color: $color-neutral-5;
        text-align: center;
        line-height: 17px;

        @include mobile {
            top: -4px;
            right: 0;
        }

        @include tablet {
            top: -4px;
            right: 9px;
        }
    }

    &-MinicartButtonWrapper {
        @include default-transition(background, 200ms, ease);

        position: relative;

        &_isActive {
            z-index: 2;
            position: relative;
            background: $color-neutral-5;
        }
    }

    &-MyAccountWrapper {
        z-index: 100;
    }
}

@include mobile {
    .Header {
        &-CompareButtonWrapper {
            top: -2px;
        }
    }
}

@include mobileAndTablet {
    .MobilePopup-Content {
        .InfoBar {
            display: block;
            padding: 0;

            &-Container {
                padding: 0;
            }

            &-Left .InfoBar-Item:first-of-type,
            &-Middle,
            &-Right {
                display: none;
            }

            &-Left {
                display: flex;
                justify-content: space-between;
                width: 100%;
                max-width: 100%;

                .InfoBar-Item {
                    font-size: 13px;
                    line-height: 19px;
                    padding: 14px 0;
                    margin: 0 21px;

                    svg {
                        margin-right: 10px;
                        display: block;
                    }
                }
            }
        }
    }
}

@include mobile {
    .Header {
        &-CompareButtonWrapper {
            top: -2px;
        }
    }
}

@include above400 {
    .Header {
        button.Header-MyAccountWrapper,
        a.Header-Button,
        button.Header-Button,
        &-Button > button {
            padding: 0 9px;
            display: block;
        }
    }
}

@include mobileAndTablet {
    .Header {
        &-Content {
            border-bottom: 1px solid $color-neutral-30;
        }
    }
}

@include tabletAndAbove {
    body {
        padding-top: 124px;
    }

    .Header {
        &-Wrapper {
            z-index: 12;
        }

        &-MobileMenuSwitcher {
            width: 48px;
            height: 48px;
            margin-right: 27px;

            span {
                width: 20px;
            }
        }

        &-ActionLabel {
            display: block;
        }

        &-ActionIcon {
            height: 34px;
        }

        .Logo {
            width: 98px;
            height: 30px;
        }
    }

    .InfoBar-Item svg {
        display: none;
    }
}

@include desktopAndAbove {
    body {
        padding-top: 150px;
    }

    .Header {
        button.Header-MyAccountWrapper,
        a.Header-Button,
        button.Header-Button,
        &-Button > button {
            padding: 12px 9px;
            display: block;
        }

        &-Content {
            padding-top: 6px;
            padding-bottom: 6px;
        }

        &-MobileMenuSwitcher {
            display: none;
        }

        &-Action {
            &_search {
                display: none;
            }
        }

        .Logo {
            width: 147px;
            height: 48px;
        }

        &-Menu {
            display: block;
        }

        &-Search {
            display: block;
            flex: 1 0;
            margin-left: 8.5%;
            margin-right: 8.5%;
        }
    }

    .InfoBar-Item svg {
        display: none;
    }
}
