@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.KeyValueTable {
    margin: 0;
    margin-block-end: 32px;
    min-width: 100%;
    font-size: 14px;

    &-Heading {
        font-size: 16px;
        font-weight: 500;
    }

    &-Item {
        &-Name {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Totals {
        &-Separated {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    .Field-Wrapper_type_radio {
        display: none;
    }
}

@include mobile {
    .KeyValueTable {
        &-Wrapper {
            overflow-x: auto;
        }
    }
}

@include tabletAndAbove {
    .KeyValueTable {
        font-size: 16px;

        &-Heading {
            font-size: 20px;
        }
    }
}
