.TypographyParagraph {
    color: $color-neutral-70;
    font-weight: 400;
    margin-bottom: 0;

    &_variant_large {
        font-size: 16px;
        line-height: 24px;
    }

    &_variant_normal {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.5px;
    }

    &_variant_small {
        font-size: 12px;
        line-height: 18px;
    }
}
