@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CarouselScroll {
    --translateY: 0;
    --animation-speed: 300ms;

    height: 100%;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-inline: auto;

    &-ContentWrapper {
        overflow: hidden;
        flex: 1;
        display: flex;
        width: var(--carousel-width);
        margin: 0 15px;
    }

    &-Content {
        transform: translateX(var(--translateX));
        transition: transform var(--animation-speed);
        padding-right: 1px;
        display: flex;
        flex-direction: row;
    }
}

