.Seo {
    margin-bottom: 60px;

    h1,
    h2,
    h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 27px;
        color: $color-neutral-90;
        margin: 46px 0 20px;
    }

    p {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: $color-neutral-70;
        margin-bottom: 16px;
    }
}
